'use strict'

const _ = require('lodash')
const MeshDockedLayer = require('santa-mesh/cjs/src/MeshDockedLayer')
const createReactElement = require('./createReactElement')

const getGridVariant = browser => browser.ie ? 'ms' : 'standard'

module.exports = {
    createPinnedLayer({id, children, childrenMeshParams, browser, wixTopAdsHeight = 0}) {
        if (_.isEmpty(children)) {
            return null
        }

        return createReactElement(MeshDockedLayer.default, {
            id: `${id}-pinned-layer`,
            key: `${id}-pinned-layer`,
            childrenMeshLayout: childrenMeshParams,
            marginTop: wixTopAdsHeight,
            isFixedLayer: true,
            growToContent: false,
            cssGridVariant: getGridVariant(browser)
        }, ...children)
    }
}